import { Injectable, EventEmitter } from "@angular/core";
import { HttpService } from "@lsl16/sustainability-shared-components";
import { environment } from "../../environments/environment";
import { SearchReturnType } from "../models/Search";
import { GeneralSupplierType, SupplierService } from '@lsl16/sushub-frontend';
@Injectable({
    providedIn: "root"
})
export class SupplierGeneralProfileService {
    public eventEmit: any;
    public eventEmitForCS: any;
    private serviceUrl: string;
    private esgService: string;
    constructor(private httpService: HttpService, private supplierService: SupplierService) {
        this.serviceUrl = environment.tsmBackendServiceURL;
        this.eventEmit = new EventEmitter();
        this.eventEmitForCS = new EventEmitter();
        this.esgService = `${environment.susHubESGServiceURL}`;
    }
    async setChangeConsentFlag(saList: any) {
        let metaDataUrl = `${this.esgService}/meta-data`
        const metaDate = await this.httpService.GetPromise(metaDataUrl)
        const getSupplierConfig = 'getSupplierConfig'
        const supplierConfig = metaDate[getSupplierConfig]
        const req  = /^(Div0)(\_[A-Za-z]*)?$/
        saList.forEach(saItem => {
            if (saItem.actionTwo) {
                supplierConfig.forEach(item => {
                    item.fieldset.forEach(subItem => {
                        if(req.test(subItem.name) && subItem.templateOptions.country.length > 0) {
                            subItem.templateOptions.country.forEach(countryItem => {
                                if (countryItem === saItem.linkCountry) {
                                    saItem.changeConsentFlag = true
                                }
                            })
                        }
                    })
                })
            }
        })
    }
    async getAllSuppliers(): Promise<any> {
        const url = `${this.serviceUrl}/supplier`;

        return await this.httpService.GetPromise(url);
    }

    async getSupplierById(supplierTsmId: string): Promise<GeneralSupplierType> {
        return await this.supplierService.getSupplierById(supplierTsmId);
    }

    async search(criteria: any): Promise<SearchReturnType> {
        const url = `${this.serviceUrl}/search/supplier${criteria}`;

        return await this.httpService.GetPromise(url);
    }

    async updateSupplier(supplierTsmId: string, data: any): Promise<any> {
        const url = `${this.serviceUrl}/supplier/${supplierTsmId}`;

        return await this.httpService.PostPromise(url, data);
    }

    async getParentSupplierByDuns(dunsNumber: string): Promise<GeneralSupplierType> {
        const url = `${this.serviceUrl}/supplier/byDUNS/${dunsNumber}`;
        return await this.httpService.GetPromise(url);
    }

    async getSubSupplierByDuns(dunsNumber: string): Promise<GeneralSupplierType> {
        const url = `${this.serviceUrl}/supplier/byDUNS/${dunsNumber}/subsupplier`;
        return await this.httpService.GetPromise(url);
    }

    async recordTime(supplierTsmId: string, data: any): Promise<GeneralSupplierType> {
        const url = `${this.serviceUrl}/supplier/recordTime/${supplierTsmId}`;
        return await this.httpService.PostPromise(url, data);
    }

    async updateCountryExtendDate(supplierTsmId: string, parentAfterUpdated: any, parentBeforeUpdated: any): Promise<GeneralSupplierType> {
        let parentNotHave = [];
        if(!parentAfterUpdated.countryServed) {
            return
        }
        if(!parentBeforeUpdated.countryServed) {
            parentNotHave = parentAfterUpdated.countryServed
        } else {
            parentAfterUpdated.countryServed.forEach(i => {
                if (!parentBeforeUpdated.countryServed.includes(i)) {
                    parentNotHave.push(i)
                }
            })
        }
      if(parentNotHave.length != 0) {
       return await this.recordTime(supplierTsmId, parentNotHave)
      } 
    }

    async getProfileByEmail(email: string): Promise<any> {
        const queryUserProfileUrl = `${this.serviceUrl}/userprofile/getUserprofile/${email}`;
        return await this.httpService.GetPromise(queryUserProfileUrl);
    }

    async duplicateCheck(tsmId: string): Promise<any> {
        const queryDuplicateSupplier = `${this.serviceUrl}/merge-profiles/duplicateCheck/${tsmId}`;
        return await this.httpService.GetPromise(queryDuplicateSupplier);
    }

    async setAcknowledgementFlag(userEmail: string): Promise<GeneralSupplierType> {
        const url = `${this.serviceUrl}/merge-profiles/setFlag`;
        return await this.httpService.PostPromise(url,{userEmail});
    }
}
