import { Component, OnInit, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { ButtonPropsType } from "@lsl16/sustainability-shared-components";
import { environment } from "src/environments/environment";
import { HttpService } from "@lsl16/sustainability-shared-components";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PopUpOnboardingComponent } from '../pop-up-onboarding/pop-up-onboarding.component';
import { PopUpStatusComponent } from "src/app/components/pop-up-status/pop-up-status.component";
import _ from "lodash";
import { EventService } from 'src/app/services/EventService.service';

@Component({
  selector: 'tsm-bv-new-onboarding',
  templateUrl: './bv-new-onboarding.component.html',
  styleUrls: ['./bv-new-onboarding.component.sass']
})
export class BvNewOnboardingComponent implements OnInit {
  @Output() refershTerminateCallBack = new EventEmitter();
  @Input() allMu: any;
  @Input() allCountry: any;
  @Input() supplier: any;
  @Input() isDuplicate = false;
  supplierTsmId: string;
  tempLocation: any = [];
  tempCompanyCode: any = [];
  tempSapId: any = [];
  onboaredDataList: any = [];
  mu: string = "";
  country: any = [];
  status: any = [];
  companyCodeFilter: any = [];
  sapIdFilter: any = [];
  servedCountryData: any = [];
  sendOnboarding: any;
  terminateStatus: any;
  statusFlag: boolean = true;
  stateData: any = [
    {
      Code: "Migrated/Not Changed",
      Text: "Migrated/Not Changed"
    },
    {
      Code: "Pending Requestor Submission",
      Text: "Pending Requestor Submission"
    },
    {
      Code: "Pending Supplier Action",
      Text: "Pending Supplier Action"
    },
    {
      Code: "Pending Supplier Submission",
      Text: "Pending Supplier Submission"
    },
    {
      Code: "Pending Internal Review",
      Text: "Pending Internal Review"
    },
    {
      Code: "Pending Risk Result",
      Text: "Pending Risk Result"
    },
    {
      Code: "Onboarded",
      Text: "Onboarded"
    }, {
      Code: "Cancelled",
      Text: "Cancelled"
    }
  ];
  constructor(private httpService: HttpService,
    public ngbModalService: NgbModal,
    public eventService: EventService) { }
  onbordingDataIf: boolean = true;
  sharedButtonProps: ButtonPropsType = {
    label: "",
    fontSize: 16,
    height: 48,
    padding: "10px"
  };
  staringOnbordingButton: ButtonPropsType;
  addCountryButton: ButtonPropsType;
  tempMarketUnit: any = [];
  dataOnbording: any = [];
  async ngOnInit(): Promise<void> {
    this.sendOnboarding = this.eventService.eventEmit.subscribe(async (value: any) => {
      if (value.indexOf("sendOnboarding") > -1) {
        const supplierTsmIdList = value.split("=");
        const supplierTsmId = supplierTsmIdList[1];
        this.getOnbordingData(supplierTsmId);
        this.refershTerminateCallBack.emit(true);
      }
    })
  }
  // get onboarding data

  async getOnbordingData(supplierTsmId) {
    const tempServedCountryDataUrl = `${environment.tsmBackendServiceURL}/search/supplier/onBoardingResult`;
    this.dataOnbording = await this.httpService.PostPromise(tempServedCountryDataUrl, { supplierTsmId: supplierTsmId })

    // this.dataOnbording = [ {},{}]
    if (this.dataOnbording.length > 0) {
      this.onbordingDataIf = false
    } else {
      this.onbordingDataIf = true
    }
    this.dataprocessing(this.dataOnbording)
  }

  // data processing

  dataprocessing(dataOnbording) {
    this.onboaredDataList = dataOnbording
    let isEmptySapList = false;
    this.onboaredDataList.forEach((item) => {
      item.companyCodeAndSapId = [];
      item.sapIds = [];
      item.companyCodes = [];
      if (item.status === "Migrated/Not Changed") {
        item.level = 1
      }
      if (item.status === "Pending Requestor Submission") {
        item.level = 1
      }
      if (item.status === "Pending Supplier Action") {
        item.level = 2
      }
      if (item.status === "Pending Supplier Submission") {
        item.level = 3
      }
      if (item.status === "Pending Internal Review") {
        item.level = 4
      }
      if (item.status === "Pending Risk Result") {
        item.level = 5
      }
      if (item.status === "Onboarded") {
        item.level = 6
      }
      if (item.status === "Cancelled") {
        item.level = "cancel"
      }
      if (item.editflag === "true") {
        item.action = "View or Edit Details"
      } else {
        item.action = "View Details"
      }
      if (item.sapListResult?.length <= 0) {
        isEmptySapList = true;
        item.companyCodeAndSapId.push({ Text: "TBD" });

        if (!item.sapIds.includes("TBD")) {
          item.sapIds.push('TBD');
        }
        if (!item.companyCodes.includes("TBD")) {
          item.companyCodes.push('TBD');
        }

      } else {
        const { data, sapIds, codes } = this.getFormattedCompanyCodeAndSap(item.sapListResult);
        item.sapIds.push(...sapIds);
        item.companyCodes.push(...codes);
        data.forEach((c, e) => {
          let endText = e + 1 !== data.length ? "," : '';
          let finalText = c.sapId?.length > 0 ? c.name + " (" + c.sapId.join(",") + ")" + endText : c.name + endText
          item.companyCodeAndSapId.push({
            Code: c.Code,
            Text: finalText
          })
        })
      }
    })
    if (isEmptySapList) {
      this.tempCompanyCode.unshift({ Code: "TBD", Text: "TBD" });
      this.tempSapId.unshift({
        Text: "TBD",
        Code: "TBD"
      })
    }
  }

  getFormattedCompanyCodeAndSap(data: any) {
    const codes = [], sap = [], map = [], uniqueMap = [];
    data.forEach((value) => {
      if (value.companyCode) codes.push(value.companyCode);
      if (value.sapId) sap.push(value.sapId)
      map.push({
        code: value?.companyCode || '',
        name: value?.companyName || '',
        sap: value?.sapId || ''
      })
    });
    const uniqueCode = codes.filter((value, index, array) => array.indexOf(value) === index);
    const uniqueSap = sap.filter((value, index, array) => array.indexOf(value) === index);
    const sapIdArr = uniqueSap.map(s => { return { Code: s, Text: s } });
    this.tempSapId.push(...sapIdArr);
    uniqueCode.forEach(j => {
      let obj = { Code: j }
      let sapId = [];
      let companyName = '';
      map.forEach((v) => {
        if (j == v.code) {
          companyName = v.name;
          if (v?.sap) sapId.push(v?.sap)
        }
      })
      uniqueMap.push({ ...obj, sapId: sapId, name: companyName });
      if (j !== "None" || j !== null || j != "") {
        this.tempCompanyCode.push({
          ...obj,
          Text: companyName
        })
      }
    });
    return { data: uniqueMap, sapIds: uniqueSap, codes: uniqueCode };
  }

  // getSingleDate

  getSingleDate(e: any) {
    if (e.Text === "Select One") {
      this.mu = ""
    } else {
      this.mu = e.Text
    }
    this.country = []
    this.filteCountry(e)
    this.search()
  }
  // get counter Date
  getcounterDate(e: any) {
    this.country = e
    this.search()
  }
  // get statusle Date
  getstatusleDate(e: any) {
    this.status = e
    this.search()
  }

  // get counter Date
  getCompanyCode(e: any) {
    this.companyCodeFilter = e
    this.search()
  }
  getSapId(e: any) {
    this.sapIdFilter = e
    this.search()
  }
  // search mu country status
  search() {
    this.onboaredDataList = []
    let array: any = []
    let couarry: any = []
    let statusarry: any = []
    let companyCodeArray: any = []
    let sapIdArray: any = []
    if (this.mu === "") {
      array = this.dataOnbording
    } else {
      this.dataOnbording.forEach((item) => {
        if (item.marketunit === this.mu) {
          array.push(item)
        }
      })
    }
    if (this.country.length > 0) {
      array.forEach((item, index) => {
        let a: boolean;
        this.country.some(i => {
          item.onboardedcountry.some(val => {
            if (val === i.Text) {
              couarry.push(item)
              a = true
              return true
            } else {
              a = false
            }
          })
          if (a) {
            return true
          }
        })
      })
      array = couarry
    }
    if (this.status.length > 0) {
      array.forEach((item, index) => {
        this.status.forEach((i) => {
          if (i.Text === item.status) {
            statusarry.push(item)
          }
          return
        })
      })
      array = statusarry
    }

    if (this.companyCodeFilter.length > 0) {
      array.forEach((item, index) => {
        let isCodeExist = false;
        this.companyCodeFilter.forEach((j) => {
          if (item.companyCodes.includes(j.Code)) {
            isCodeExist = true;
          }
          return
        })
        if (isCodeExist)
          companyCodeArray.push(item)
      })
      array = companyCodeArray
    }

    if (this.sapIdFilter.length > 0) {
      array.forEach((item, index) => {
        let isCodeExist = false;
        this.sapIdFilter.forEach((j) => {
          if (item.sapIds.includes(j.Code)) {
            isCodeExist = true;
          }
          return
        })
        if (isCodeExist)
          sapIdArray.push(item)
      })
      array = sapIdArray
    }
    this.onboaredDataList = array
  }
  // filte country

  filteCountry(e) {
    let dataArry = []
    if (e.Text !== "Select One") {
      this.servedCountryData.forEach((item) => {
        if (item.marketUnit) {
          if (item.marketUnit === e.Code) {
            dataArry.push(item)
          }
        }
      })
    } else {
      dataArry = this.servedCountryData
    }

    this.tempLocation = []
    this.getServedCountryList(dataArry)
  }

  // get resource for Select Country/ies

  getServedCountryList(servedData): any {
    servedData.forEach(element => {
      const state = element.countryServedEnable;
      if (state === true) {
        const country = {
          Code: element.tsmCode,
          Text: element.countryServedName,
        };
        this.tempLocation.push(country);
      }
    });
    this.tempLocation.sort((obj1: any, obj2: any) => {
      const val1 = obj1.Text;
      const val2 = obj2.Text;
      if (val1 > val2) {
        return 1;
      } else if (val1 < val2) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  jump(item) {
    let envParams, detailEnvParams = environment.env
    if (environment.env == "dev") {
      envParams = detailEnvParams = "de";
    } else if (environment.env == "test") {
      envParams = detailEnvParams = "te";
    } if (environment.env == "perf") {
      envParams = detailEnvParams = "pe";
    } if (environment.env == "prod") {
      envParams = "pr";
    } if (environment.env == "stage") {
      envParams = "st";
    } if (environment.env == "local") {
      envParams = detailEnvParams = "local";
    } if (environment.env == "playground") {
      envParams = detailEnvParams = "play";
    }
    if ("Cancelled" === item.status) {
      const url = `${environment.supplierHubOnboardingViewURL}`;
      window.open(url, "_blank")
    } else {
      if (JSON.parse(item.editflag)) {
        const params = (environment.env == "prod" || environment.env == "stage") ? "" : `?ciotsm=${detailEnvParams}`
        const url = `${environment.supplierHubOnboardingViewURL}/onboarding/${item.suppliercode}/step1` + params;
        window.open(url, "_blank")
      } else {
        const url = `${environment.supplierHubOnboardingViewURL}/onboarding/process/${item.suppliercode}?ciotsm=${envParams}`;
        window.open(url, "_blank")
      }
    }
  }

  sortSelectResource = (resource: any): any => {
    return resource?.sort((obj1: any, obj2: any) => {
      const val1 = obj1.Text;
      const val2 = obj2.Text;
      if (val1 > val2) {
        return 1;
      } else if (val1 < val2) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes['allMu'] !== undefined) {
      if (changes['allMu'].currentValue !== undefined) {
        this.tempMarketUnit = _.cloneDeep(changes['allMu'].currentValue)
        this.tempMarketUnit = this.sortSelectResource(this.tempMarketUnit)
        this.tempMarketUnit.unshift({

          Text: "Select One",
          Code: "Select One"
        })
      }
    }
    if (changes['allCountry'] !== undefined) {
      if (changes['allCountry'].currentValue !== undefined) {
        this.servedCountryData = changes['allCountry'].currentValue;
        this.buttonType()
        this.getServedCountryList(this.servedCountryData)
      }
    }
    if (changes['supplier'] !== undefined) {
      if (changes['supplier'].currentValue !== undefined) {
        this.supplierTsmId = changes['supplier'].currentValue.supplierTsmId;
        this.getOnbordingData(this.supplierTsmId)
      }
      this.terminateStatus = this.supplier && this.supplier.status ? this.supplier.status : '';
      if (this.terminateStatus == "Terminated") {//Terminated
        this.statusFlag = false;
      }
    }
    this.staringOnbordingButton.disabled = this.isDuplicate;
  }
  buttonType() {
    this.staringOnbordingButton = {
      ...this.sharedButtonProps,
      width: 230,
      color: "white",
      backgroundColor: "blue",
      disabled: false,
      borderColor: "blue",
      label: "Start Onboarding",
      icon: "assets/icons/link-out.svg",
      clickIcon: "assets/icons/link-out.svg",
      iconWidth: 18,
      iconHeight: 17,
      iconPosition: "right",
      onClick: () => {
        this.onboardingPopUp();
      },
    };
    this.addCountryButton = {
      ...this.sharedButtonProps,
      width: 230,
      color: "white",
      backgroundColor: "blue",
      borderColor: "blue",
      label: "Onboard new country",
      onClick: () => {
        this.onboardingPopUp();
      },
    };
  }
  onboardingPopUp = async () => {
    const confirmType = {
      options: {},
      centered: true,
      size: "lg",
      supplier: this.supplier,
      windowClass: "modal-onboarding"
    }
    const modalReference = this.ngbModalService.open(PopUpOnboardingComponent, confirmType);
    modalReference.componentInstance.confirmType = confirmType;
  }
  showStatusPopup(): void {
    const modalReference = this.ngbModalService.open(PopUpStatusComponent);
  }

  ngOnDestroy(): void {
    const subList = [this.sendOnboarding];
    subList.forEach((item) => {
      if (!!item) {
        item.unsubscribe();
      }
    });
  }
}
